:root {
  --color_primary: #00497bf2;
  --color_secondary: #0075b3f2;
  --color_detail: #00273cf2;
  --color_primary_70: #00497bb3;
  --color_secondary_70: #0075b3b3;
  --color_detail_70: #00273cb3;
  --color_primary_50: #00497b80;
  --color_secondary_50: #0075b380;
  --color_detail_50: #00273c80;
  --color_primary_30: #00497b4d;
  --color_secondary_30: #0075b34d;
  --color_detail_30: #00273c4d;
}

.ol-box {
  box-sizing: border-box;
  background-color: #fff6;
  border: 1.5px solid #b3c5db;
  border-radius: 2px;
}

.ol-mouse-position {
  position: absolute;
  top: 8px;
  right: 8px;
}

.ol-scale-line {
  background: var(--color_primary_70);
  border-radius: 4px;
  padding: 2px;
  position: absolute;
  bottom: 8px;
  left: 10px;
}

.ol-scale-line-inner {
  color: #eee;
  text-align: center;
  will-change: contents, width;
  border: 1px solid #eee;
  border-top: none;
  margin: 1px;
  font-size: 10px;
  transition: all .25s;
}

.ol-scale-bar {
  position: absolute;
  bottom: 8px;
  left: 8px;
}

.ol-scale-step-marker {
  width: 1px;
  height: 15px;
  float: right;
  z-index: 10;
  background-color: #000;
}

.ol-scale-step-text {
  z-index: 11;
  color: #000;
  text-shadow: -2px 0 #fff, 0 2px #fff, 2px 0 #fff, 0 -2px #fff;
  font-size: 12px;
  position: absolute;
  bottom: -5px;
}

.ol-scale-text {
  text-align: center;
  color: #000;
  text-shadow: -2px 0 #fff, 0 2px #fff, 2px 0 #fff, 0 -2px #fff;
  font-size: 14px;
  position: absolute;
  bottom: 25px;
}

.ol-scale-singlebar {
  height: 10px;
  z-index: 9;
  box-sizing: border-box;
  border: 1px solid #000;
  position: relative;
}

.ol-unsupported {
  display: none;
}

.ol-unselectable, .ol-viewport {
  -webkit-touch-callout: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.ol-viewport canvas {
  all: unset;
}

.ol-selectable {
  -webkit-touch-callout: default;
  user-select: text;
}

.ol-grabbing {
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}

.ol-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}

.ol-control {
  background-color: #fff6;
  border-radius: 4px;
  padding: 2px;
  position: absolute;
}

.ol-control:hover {
  background-color: #fff9;
}

.ol-zoom {
  top: .5em;
  left: .5em;
}

.ol-rotate {
  transition: opacity .25s linear, visibility linear;
  top: .5em;
  right: .5em;
}

.ol-rotate.ol-hidden {
  opacity: 0;
  visibility: hidden;
  transition: opacity .25s linear, visibility 0s linear .25s;
}

.ol-zoom-extent {
  top: 4.643em;
  left: .5em;
}

.ol-full-screen {
  top: .5em;
  right: .5em;
}

.ol-control button {
  color: #fff;
  font-weight: 700;
  font-size: inherit;
  text-align: center;
  height: 1.375em;
  width: 1.375em;
  background-color: var(--color_primary_70);
  border: none;
  border-radius: 2px;
  margin: 1px;
  padding: 0;
  line-height: .4em;
  text-decoration: none;
  display: block;
}

.ol-control button::-moz-focus-inner {
  border: none;
  padding: 0;
}

.ol-zoom-extent button {
  line-height: 1.4em;
}

.ol-compass {
  will-change: transform;
  font-size: 1.2em;
  font-weight: 400;
  display: block;
}

.ol-touch .ol-control button {
  font-size: 1.5em;
}

.ol-touch .ol-zoom-extent {
  top: 5.5em;
}

.ol-control button:focus, .ol-control button:hover {
  background-color: var(--color_secondary_70);
  text-decoration: none;
}

.ol-zoom .ol-zoom-in {
  border-radius: 2px 2px 0 0;
}

.ol-zoom .ol-zoom-out {
  border-radius: 0 0 2px 2px;
}

.ol-attribution {
  text-align: right;
  max-width: calc(100% - 1.3em);
  flex-flow: row-reverse;
  align-items: center;
  display: flex;
  bottom: 32px;
  left: .5em;
}

.ol-attribution a {
  color: var(--color_detail_70);
  text-decoration: none;
}

.ol-attribution ul {
  color: #000;
  text-shadow: 0 0 2px #fff;
  margin: 0;
  padding: 1px .5em;
  font-size: 12px;
}

.ol-attribution li {
  list-style: none;
  display: inline;
}

.ol-attribution li:not(:last-child):after {
  content: " ";
}

.ol-attribution img {
  max-height: 2em;
  max-width: inherit;
  vertical-align: middle;
}

.ol-attribution button {
  flex-shrink: 0;
}

.ol-attribution.ol-collapsed ul {
  display: none;
}

.ol-attribution:not(.ol-collapsed) {
  background: #fffc;
}

.ol-attribution.ol-uncollapsible {
  border-radius: 4px 0 0;
  bottom: 0;
  right: 0;
}

.ol-attribution.ol-uncollapsible img {
  max-height: 1.6em;
  margin-top: -.2em;
}

.ol-attribution.ol-uncollapsible button {
  display: none;
}

.ol-zoomslider {
  height: 200px;
  top: 4.5em;
  left: .5em;
}

.ol-zoomslider button {
  height: 10px;
  position: relative;
}

.ol-touch .ol-zoomslider {
  top: 5.5em;
}

.ol-overviewmap {
  bottom: .5em;
  left: .5em;
}

.ol-overviewmap.ol-uncollapsible {
  border-radius: 0 4px 0 0;
  bottom: 0;
  left: 0;
}

.ol-overviewmap .ol-overviewmap-map, .ol-overviewmap button {
  display: block;
}

.ol-overviewmap .ol-overviewmap-map {
  height: 150px;
  width: 150px;
  border: 1px solid #7b98bc;
  margin: 2px;
}

.ol-overviewmap:not(.ol-collapsed) button {
  position: absolute;
  bottom: 2px;
  left: 2px;
}

.ol-overviewmap.ol-collapsed .ol-overviewmap-map, .ol-overviewmap.ol-uncollapsible button {
  display: none;
}

.ol-overviewmap:not(.ol-collapsed) {
  background: #fffc;
}

.ol-overviewmap-box {
  border: 2px dotted var(--color_detail_70);
}

.ol-overviewmap .ol-overviewmap-box:hover {
  cursor: move;
}

/*# sourceMappingURL=index.fdcc27d2.css.map */
