:root {
	--color_primary: rgba(0, 73, 123, 0.95);
	--color_secondary: rgba(0, 117, 179, 0.95);
	--color_detail: rgba(0, 39, 60, 0.95);

	--color_primary_70: rgba(0, 73, 123, 0.7);
	--color_secondary_70: rgba(0, 117, 179, 0.7);
	--color_detail_70: rgba(0, 39, 60, 0.7);

	--color_primary_50: rgba(0, 73, 123, 0.5);
	--color_secondary_50: rgba(0, 117, 179, 0.5);
	--color_detail_50: rgba(0, 39, 60, 0.5);

	--color_primary_30: rgba(0, 73, 123, 0.3);
	--color_secondary_30: rgba(0, 117, 179, 0.3);
	--color_detail_30: rgba(0, 39, 60, 0.3);
}
